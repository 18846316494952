export const CREATE_NOSHE_SUBSCRIPTION_MUTATION = `
    mutation ($input: NosheSubscriptionInput!) {
        createNosheSubscription(input: $input) {
            uuid
        }
    }
`;

export const CREATE_COMPANY_MUTATION = `
mutation ($input: CompanyInput!) {
  createCompany(input: $input) {
    uuid
  }
}
`;
