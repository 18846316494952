import {ComboBox} from "../../comboBox/ComboBox.jsx";
import '../../../scss/PowerTabs.scss';
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {makeApolloClientCall} from "../../../api/makeApolloApiCall.js";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Dropdown} from "../../dropdown/Dropdown.jsx";
import {EmailTextBox} from "../../emailTextBox/EmailTextBox.jsx";
import {validate} from "./CreateSubscriptionValidationRules.js";
import {Button, Checkbox, CircularProgress, FormControlLabel, TextField} from "@mui/material";
import {shouldSearchForBrand} from "./shouldSearchForBrand.js";
import {AGGREGATION_PERIODS, ALL_BRANDS, CUSTOM_SLICERS, PRODUCT_UNIVERSES, REPORT_TYPE} from "./constants.js";
import {setForm} from "./SetForm.jsx";
import ConfirmationDialog from "../common/dialog/ConfirmationDialog.jsx";

export const SDN_QUERY = `query {
        markets{
            value:index,
            market_key,
            displayName:description,
            type:geography_type,
            geographyLevel:geography_level
            accountTypeId:account_type_id
        }
        categories:sdnDeliveryPeriodSnapshotCategories(first: 10000) {
            edges {
              node {
                subcategories {
                  edges {
                    node {
                      displayName:description
                      value:tag
                    }
                  }
                }
                displayName:description
                value:tag
              }
            }
          }
        productAttributes{
            displayName
            value:id
        }
        dataGroups {
            displayName
            value:marketKeys
            categories
            subcategories
        }
        accountTypes:accountType{
            displayName:name
            value:index
        }
    }`
export const BRANDS_QUERY = `query($contains: String) {
     brands(limit: 2000 filter: {
      name: {
        eq: $contains
      }
    }){
      value:id
      displayName:name
    }
}`

export const UPSERT_QUERY = `mutation($data: PowertabsSubscriptionInput!){
  upsertPowertabsSubscription(data: $data) {
    filePreFixName
  }
}`

export const SubscriptionForm = ({initialState}) => {
    const [errors, setErrors] = useState({})
    const [isEdit] = useState(Boolean(initialState.id))
    const [id, setId] = useState(initialState.id)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [brandSearchKey, setBrandSearchKey] = useState("")
    const [isSearching, setIsSearching] = useState(false)
    const [clientBrands, setClientBrands] = useState("")
    const [competitorBrands, setCompetitorBrands] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    const [markets, setMarkets] = useState([])
    const [retailAccounts, setRetailAccounts] = useState([])
    const [categories, setCategories] = useState([])
    const [data, setData] = useState({
        markets: [],
        retail_accounts: [],
        categories: [],
        brands: [],
        productAttributes: [],
        competitorBrands: [],
        report_type: REPORT_TYPE,
        customSlicers: CUSTOM_SLICERS,
        productUniverses: PRODUCT_UNIVERSES,
        aggregationPeriods: AGGREGATION_PERIODS,
        dataGroups: [],
        accountType: [],
        form: setForm(initialState)
    })

    const [openOverwriteDialog, setOpenOverwriteDialog] = useState(false)
    const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false)

    const handleDialogClose = (value, setter) => {
        setter(value)
    }

    const handleConfirm = (value, dialogCloseSetter) => {
        dialogCloseSetter(false)
        if (value) {
            validateForm()
            setIsSubmitting(true)
        }
    }

    const handleAccountTypeChange = (accountType) => {
        const currentRetailAccounts = data.retail_accounts.filter(item => accountType?.value === item.accountTypeId && item.geographyLevel !== "CRMA")
        setData({
                ...data,
                form: {
                    ...data.form,
                    retail_accounts: currentRetailAccounts,
                }
            }
        )
    }

    const handleDataGroupChange = (dataGroup) => {
        const currentMarkets = markets.filter(item => dataGroup ? dataGroup?.value?.includes(parseInt(item.market_key)) : true)
        const currentRetailAccounts = retailAccounts.filter(item => dataGroup ? dataGroup?.value?.includes(parseInt(item.market_key)) : true)
        const currentCategories = categories.filter(item => dataGroup ? dataGroup?.categories?.includes(item.value) : true)
        const currentCategoriesWithFilteredSubcategories = currentCategories.map(category => {
            return {
                ...category,
                subcategories: category.subcategories.filter(subcat => dataGroup ? dataGroup?.subcategories?.includes(subcat.value) : true)
            }
        })
        setData(
            {
                ...data,
                markets: currentMarkets,
                retail_accounts: currentRetailAccounts,
                categories: currentCategoriesWithFilteredSubcategories,
                form: {
                    ...data.form,
                    markets: [],
                    retail_accounts: [],
                    categories: [],
                    subcategories: []
                }
            }
        )
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting) {
            let message = "Create Subscription started."

            if (!id && isEdit) {
                message = "Duplication Started"
            } else if (isEdit) {
                message = "Overwriting subscription"
            }

            toast.info(message)
            console.log("=============> data.form: ", data.form);
            const valueMap = ({value}) => value.toString();
            const variables = {
                data: {
                    id,
                    filePreFixName: data.form.filePreFixName,
                    clientBrandIds: data.form.clientBrands.map(valueMap),
                    reportType: data.form.report_type,
                    channelIds: data.form.markets.map(valueMap),
                    keyAccountIds: data.form.retail_accounts.map(valueMap),
                    categoryIds: data.form.categories.map(valueMap),
                    subcategoryIds: data.form.subcategories.map(valueMap),
                    competitorBrandIds: data.form.competitorBrands.map(valueMap),
                    productAttributeIds: data.form.productAttributes.map(valueMap),
                    customSlicers: data.form.customSlicers.trim(),
                    poshtaBrandCode: data.form.poshtaBrandCode,
                    toEmail: data.form.toEmail,
                    ccEmail: data.form.ccEmail,
                    aggregateProductUniverses: data.form.aggregateProductUniverses.map(valueMap),
                    upcProductUniverse: data.form.upcProductUniverse,
                    aggregationPeriods: data.form.aggregationPeriods.map(valueMap),
                    newTemplate: data.form.newTemplate
                }
            }
            makeApolloClientCall(UPSERT_QUERY, false, variables).then(() => {
                toast.success("Subscription Saved");
            }).catch((error) => {
                toast.error(`${error.networkError.result.errors[0].message}`);
            })
        }
        setIsSubmitting(false)
        setId(initialState.id)
    }, [errors, isSubmitting])

    const getNodeValueAndDisplayNameFromNode = (node) => {
        return {
            value: node.value,
            displayName: node.displayName
        }
    }

    useEffect(() => {
        makeApolloClientCall(SDN_QUERY)
            .then((response) => {
                const filteredMarkets = response.markets.filter(item => item.type?.toLowerCase() === "market")
                const retail_accounts = response.markets.filter(item => item.type?.toLowerCase() === "accounts")
                const categoriesFromInitialState = data.form.categories?.map(item => item.value)
                const filteredCategories = response.categories.edges.map(cat => {
                    return {
                        ...getNodeValueAndDisplayNameFromNode(cat.node),
                        subcategories: cat.node.subcategories.edges.map(subcat => {
                            return {
                                ...getNodeValueAndDisplayNameFromNode(subcat.node)
                            }
                        })
                    }
                })
                setMarkets(filteredMarkets)
                setRetailAccounts(retail_accounts)
                setCategories(filteredCategories)
                setData((oldData) => ({
                    ...oldData,
                    markets: filteredMarkets,
                    retail_accounts,
                    productAttributes: response.productAttributes,
                    categories: filteredCategories,
                    dataGroups: response.dataGroups,
                    accountTypes: response.accountTypes,
                    form: {
                        ...oldData.form,
                        categories: filteredCategories.filter(category => categoriesFromInitialState.includes(category.value))
                    }
                }))
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        makeApolloClientCall(BRANDS_QUERY, false, {
            contains: brandSearchKey
        })
            .then((response) => {
                setData((oldData) => ({
                    ...oldData,
                    brands: response.brands
                }))
            })
        setIsSearching(false)
    }, [brandSearchKey])

    const handleFormChanges = (form, component, value) => {
        form[component] = value
        return form
    }

    const onChange = (value, stateKey) => {
        const form = handleFormChanges(data.form, stateKey, value)
        clearErrorFor(stateKey)
        setData({
            ...data,
            form
        })
    };

    const validateForm = () => {
        setErrors({})
        setErrors(validate(data.form))
    }

    const clearErrorFor = (name) => {
        setErrors({
                ...errors,
                [name]: undefined
            }
        )
    }

    const handleSubmit = (event, dialogStateSetter) => {
        event.preventDefault()
        if (isEdit) {
            dialogStateSetter(true)
        } else {
            validateForm()
            setIsSubmitting(true)
        }
        return {}
    }

    const debounced = useCallback(shouldSearchForBrand(setIsSearching, setBrandSearchKey), [])
    const mappedSubcategories = data.form.categories.map(category => category.subcategories).flat()
    if (isLoading) {
        return (<CircularProgress/>)
    }
    return (
        <div className="create-container">
            <ToastContainer/>
            <div className="create header">
                <h3>Subscription</h3>
            </div>
            <div className="create-form">
                <form onSubmit={handleSubmit}>
                    <div className="new-template-checkbox-container">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={data.form.newTemplate}
                                    onChange={(event) =>
                                        onChange(event.target.checked, "newTemplate")
                                    }
                                    role={"new-template-checkbox"}
                                />
                            }
                            label="New Template"
                        />
                    </div>
                    <div className="create-powertabs-subscription">
                        <TextField
                            label="File Prefix Name"
                            id="filePreFixName"
                            className="item"
                            value={data.form.filePreFixName}
                            error={!!errors.filePreFixName}
                            helperText={errors.filePreFixName}
                            onChange={
                                (event) => onChange(event.target.value, "filePreFixName")
                            }
                        />
                        <TextField
                            label="Poshta Brand Code"
                            id="poshtaBrandCode"
                            className="item"
                            value={data.form.poshtaBrandCode}
                            error={!!errors.poshtaBrandCode}
                            helperText={errors.poshtaBrandCode}
                            onChange={
                                (event) => onChange(event.target.value.replaceAll(" ", "-"), "poshtaBrandCode")
                            }
                        />
                        <Dropdown
                            onChange={(event) => onChange(event.target.value, "report_type")}
                            data={data.report_type}
                            label="Report Type"
                            className="item"
                            required={true}
                            currentValue={data.form.report_type}
                        />
                        <Dropdown
                            onChange={(event) => onChange(event.target.value, "customSlicers")}
                            data={data.customSlicers}
                            label="Custom Slicers"
                            className="item"
                            currentValue={data.form.customSlicers}
                        />
                        <div className="email-recipient">
                            <EmailTextBox
                                label="To Email Recipients"
                                value={data.form.toEmail}
                                className="item"
                                error={errors.toEmail}
                                setError={(value) => setErrors({
                                        ...errors,
                                        toEmail: value
                                    }
                                )}
                                onChange={(value) => onChange(value, "toEmail")}
                            />
                        </div>
                        <div>
                            <EmailTextBox
                                label="CC Email Recipients"
                                className="item"
                                value={data.form.ccEmail}
                                setError={(value) => setErrors({
                                        ...errors,
                                        ccEmail: value
                                    }
                                )}
                                onChange={(value) => onChange(value, "ccEmail")}
                            />
                        </div>
                        <ComboBox
                            label="Data Group"
                            data={data.dataGroups}
                            isMultiple={false}
                            disableCloseOnSelect={false}
                            onChange={(_event, value) => handleDataGroupChange(value)}
                        />
                        <ComboBox
                            label="US/Region/Market"
                            data={data.markets}
                            error={errors.markets}
                            value={data.form.markets}
                            onChange={(_event, value) => onChange(value, "markets")}
                        />
                        <ComboBox
                            label="Account Type"
                            data={data.accountTypes}
                            isMultiple={false}
                            disableCloseOnSelect={false}
                            onChange={(_event, value) => handleAccountTypeChange(value)}
                        />
                        <div className="retail-accounts">
                            <ComboBox
                                label="Retail Accounts"
                                data={data.retail_accounts}
                                error={errors.retail_accounts}
                                value={data.form.retail_accounts}
                                onChange={(_event, value) => onChange(value, "retail_accounts")}
                            />
                        </div>
                        <ComboBox
                            label="Categories"
                            data={data.categories}
                            error={errors.categories}
                            value={data.form.categories}
                            onChange={async (_event, value) => {
                                const allSubcategories = value.map(category => category.subcategories).flat();
                                onChange(value, "categories")
                                onChange(data.form.subcategories.filter(subcategory => allSubcategories.includes(subcategory)), "subcategories")
                            }
                            }
                        />
                        <ComboBox
                            label="Subcategories"
                            error={errors.subcategories}
                            value={data.form.subcategories}
                            data={mappedSubcategories ? mappedSubcategories : []}
                            onChange={(_event, value) => onChange(value, "subcategories")}
                        />
                        <ComboBox
                            label="Client Brands"
                            value={data.form.clientBrands}
                            error={errors.clientBrands}
                            data={data.brands}
                            isLoading={isSearching}
                            inputValue={clientBrands}
                            onInputChange={(_e, value, reason) => {
                                if (reason !== "reset") {
                                    setClientBrands(value)
                                }
                                debounced(value)
                            }}
                            onChange={(_event, value) => onChange(value, "clientBrands")}
                        />
                        <ComboBox
                            label="Competitor Brands"
                            value={data.form.competitorBrands}
                            error={errors.competitorBrands}
                            data={ALL_BRANDS.concat(data.brands)}
                            isLoading={isSearching}
                            inputValue={competitorBrands}
                            onInputChange={(_e, value, reason) => {
                                if (reason !== "reset") {
                                    setCompetitorBrands(value)
                                }
                                debounced(value)
                            }}
                            onChange={(_event, value) => onChange(value, "competitorBrands")}
                        />
                        <ComboBox
                            label="Product Attributes"
                            data={data.productAttributes}
                            value={data.form.productAttributes}
                            onChange={(_event, value) => onChange(value, "productAttributes")}
                        />
                        <ComboBox
                            label="Aggregate Product Universes"
                            data={data.productUniverses}
                            error={errors.aggregateProductUniverses}
                            value={data.form.aggregateProductUniverses}
                            onChange={(_event, value) => onChange(value, "aggregateProductUniverses")}
                        />
                        <Dropdown
                            onChange={(event) => onChange(event.target.value, "upcProductUniverse")}
                            data={data.productUniverses}
                            label="UPC Product Universe"
                            className="item"
                            required={true}
                            currentValue={data.form.upcProductUniverse}
                        />
                        <ComboBox
                            label="Aggregation Periods"
                            data={data.aggregationPeriods}
                            error={errors.aggregationPeriods}
                            value={data.form.aggregationPeriods}
                            skipSort={true}
                            onChange={(_event, value) => onChange(value, "aggregationPeriods")}
                        />
                    </div>
                </form>
            </div>
            <div className="buttons-container">
                <div>
                    <Button type="button" variant="outlined" disabled={isSubmitting}
                            onClick={(event) => handleSubmit(event, setOpenOverwriteDialog)}>{isEdit ? "Save Changes" : "Create"}</Button>
                </div>
                <div>
                    {isEdit ? <Button type="button" variant="outlined" disabled={isSubmitting}
                                      onClick={async (event) => {
                                          setId(null)
                                          handleSubmit(event, setOpenDuplicateDialog)
                                      }}>Duplicate</Button> : null}
                </div>
            </div>
            <div>
                <ConfirmationDialog
                    isOpen={openOverwriteDialog}
                    message={"Are you sure you want to overwrite " + data.form.filePreFixName + "?"}
                    handleClose={(value) => handleDialogClose(value, setOpenOverwriteDialog)}
                    handleConfirm={(value) => handleConfirm(value, setOpenOverwriteDialog)}
                    title={"Overwrite Changes"}
                />
                <ConfirmationDialog
                    isOpen={openDuplicateDialog}
                    message={"Do you want to create a new subscription with file prefix name: " + data.form.filePreFixName + "?"}
                    handleClose={(value) => handleDialogClose(value, setOpenDuplicateDialog)}
                    handleConfirm={(value) => handleConfirm(value, setOpenDuplicateDialog)}
                    title={"Duplicate subscription"}
                />
            </div>


        </div>
    )
}


