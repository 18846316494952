import JwtDecode from 'jwt-decode'
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {v4 as uuidv4} from 'uuid';

export const DEFAULT_PATH = '/'

export function isTokenExpired(jwtDecode) {
    return jwtDecode.exp < Math.floor(Date.now() / 1000)
}

export function setRedirectLocalStorage(redirectLocation) {
    window.localStorage.setItem(
        'redirect',
        JSON.stringify({
            location: redirectLocation,
            state: uuidv4()
        })
    );
}

export const getOptionalAuthorizationRedirect = (accessToken, redirectLocation, path) => {
    if (accessToken === null) {
        setRedirectLocalStorage(redirectLocation);
        return 'login';
    }
    const jwtDecode = JwtDecode(accessToken);
    const permissions = getUserPermissions(accessToken);

    if(isTokenExpired(jwtDecode)){
        setRedirectLocalStorage(redirectLocation);
        return 'logout';
    }

    if (path === DEFAULT_PATH && permissions.length > 0) {
        return permissions[0]
    }

    return undefined;
};


export const getUserPermissions = (accessToken) => {
    if (!accessToken) {
        return []
    }

    const jwtDecode = JwtDecode(accessToken);
    return jwtDecode['permissions']
}