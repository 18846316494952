import React, {useState} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Popover} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {Info, Pause, PlayArrow, PlayCircle} from "@mui/icons-material";
import {useFetchDagInfo, useFetchDagRuns} from "./Dags.hooks.jsx";
import {COLUMNS, DAG_IDS, STATE_MAPPER} from "./Dags.constants.js";
import {handlePaused, handleScorecardsTrigger} from "./Dags.handlers.js";


export function Dags() {
    const auth0AccessToken = window.localStorage.getItem('access_token')
    const dagRuns = useFetchDagRuns(auth0AccessToken)
    const [dagInfo, updateDagInfo] = useFetchDagInfo(auth0AccessToken)
    const [anchorEl, setAnchorEl] = useState([null, null, null]);

    return (
        <>
            {
                DAG_IDS.map(({dagId, display}, index) => {
                        const latestState = dagRuns[index]?.[0]?.state
                        const {is_paused: isPaused, ...otherFields} = dagInfo?.[index] ?? {isPaused: false}
                        const handleClick = (event) => {
                            setAnchorEl((p) => {
                                    const newValue = [...p]
                                    newValue[index] = event.currentTarget
                                    return newValue
                                }
                            )
                        }
                        const handleClose = () => {
                            setAnchorEl((p) => {
                                    const newValue = [...p]
                                    newValue[index] = null
                                    return newValue
                                }
                            );
                        };

                        const open = Boolean(anchorEl[index]);
                        const id = open ? `simple-popover-${index}` : undefined;
                        return (
                            <Accordion key={dagId}>
                                <AccordionSummary>
                                    <Chip color={STATE_MAPPER[latestState]}
                                          label={latestState} clickable={false}/> &nbsp;
                                    <Button
                                        onClick={() => handleScorecardsTrigger(dagId, auth0AccessToken)}><PlayCircle/> Trigger</Button>&nbsp;
                                    <Button
                                        onClick={() => handlePaused(dagId, isPaused, auth0AccessToken, updateDagInfo(index))}> {isPaused ? <>
                                        <PlayArrow/> Resume</> : <> <Pause/> Pause</>}</Button>&nbsp;
                                    {display} &nbsp;
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Info aria-describedby={id} onClick={handleClick} data-testid={`info-${index}`}/>
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl[index]}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <Box sx={{width: "400px"}}>
                                            {dagId}
                                            <div>
                                                <pre>{JSON.stringify(otherFields, null, 1)}</pre>
                                            </div>
                                        </Box>

                                    </Popover>
                                    <DataGrid columns={COLUMNS} rows={dagRuns[index]} getRowId={(row) => row.dag_run_id}/>
                                </AccordionDetails>
                            </Accordion>
                        )
                    }
                )
            }

        </>)
}