export function setForm(initialState) {
    return {
        markets: initialState.channels ?? [],
        retail_accounts: initialState.keyAccounts ?? [],
        categories: initialState.categories ?? [],
        productAttributes: initialState.productAttributes ?? [],
        subcategories: initialState.subcategories ?? [],
        toEmail: initialState.toEmail ?? [],
        ccEmail: initialState.ccEmail ?? [],
        clientBrands: initialState.clientBrands,
        competitorBrands: initialState.competitorBrands,
        report_type: initialState.report_type,
        customSlicers: initialState.customSlicers ?? " ",
        filePreFixName: initialState.filePreFixName,
        poshtaBrandCode: initialState.poshtaBrandCode,
        aggregateProductUniverses: initialState.aggregateProductUniverses,
        upcProductUniverse: initialState.upcProductUniverse,
        aggregationPeriods: initialState.aggregationPeriods,
        newTemplate: initialState.newTemplate ?? false
    };
}
