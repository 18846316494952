import {GraphqlQueryTable} from "../GraphqlQueryTable.jsx";

export const INSTANCES_QUERY = `query {
        data:powertabsInstances {
           name
         }
    }`

export const INSTANCES_COLUMNS = () => [
    {
        Header: 'Instance Name',
        accessor: 'name'
    }
]

export const PowertabsInstances = () => {
    return (
        <GraphqlQueryTable
            pageName="Powertabs Instances"
            generateColumns={INSTANCES_COLUMNS}
            query={INSTANCES_QUERY}
        />
    )
}

