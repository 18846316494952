import {Chip} from "@mui/material";
import React from "react";
export const DAG_IDS = [
    {dagId: "datascience-workspace-vendorscorecard.vendorscorecard-pipeline", display: "Vendor Scorecard"},
    {
        dagId: "datascience-workspaces-sprouts-business-review-scorecard.brs",
        display: "Sprouts Business Review Scorecard"
    },
    {dagId: "datascience-workspace-ncg-retailerdashboard.retailer-dashboard", display: "NCG Retailer Dashboard"},
]
export const STATE_MAPPER = {
    "queued": "default",
    "running": "info",
    "success": "success",
    "failed": "error"
}
export const dateToLocaleString = ({value}) => new Date(value).toLocaleString()

export const COLUMNS = [
    {
        field: "state",
        headerName: "State",
        renderCell: ({value}) => <Chip color={STATE_MAPPER[value]} label={value} clickable={false}/>
    },
    {field: "dag_run_id", headerName: "Run ID", minWidth: 300},
    {
        field: "execution_date",
        headerName: "Execution Date",
        valueFormatter: dateToLocaleString,
        minWidth: 200
    },
    {
        field: "start_date",
        headerName: "Start Date",
        valueFormatter: dateToLocaleString,
        minWidth: 200
    },
    {
        field: "end_date",
        headerName: "End Date",
        valueFormatter: dateToLocaleString,
        minWidth: 200
    },
    {field: "run_type", headerName: "Run Type"},
    {field: "external_trigger", headerName: "External?"},
]
