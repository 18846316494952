import React, { Fragment } from "react";
import HeaderTile from "./HeaderTile.jsx";
import logo from "../../assets/spins-logo.svg";
import HeaderDropdown from "./HeaderDropdown.jsx";
import { forceLogout } from "@spins-gcp/auth0-react";

export const Header = () => {
  return (
    <Fragment>
      <div className={`App App-${process.env.REACT_APP_ENV}`}>
        <div className={"title-bar-container"}>
          <div className={"header-section"}>
            <img
              src={logo}
              alt={"SPINS logo"}
              className={"columns small-1 spins-logo"}
            />
            <div className={"dashboard-title"}>OfficeDex</div>
          </div>
          <button
            id={"logout-button"}
            className={"button secondary header-button"}
            onClick={forceLogout}
            data-alignment="center"
          >
            Logout
          </button>
        </div>
      </div>
      <div className={"nav-bar"}>
        <div className={"nav-title-bar"}>
          <nav
            className={"nav z-to-the-top"}
            data-animate="hinge-in-from-top spin-out"
          >
            <HeaderTile
              menuItems={[{ name: "Explorer", url: "/explorer" }]}
              key={"Explorer"}
            />
            <HeaderDropdown
              key={"Scorecards"}
              title={"Scorecards"}
              menuItems={[
                {
                  name: "Create Contract",
                  url: "/scorecards/create-contract",
                },
                {
                  name: "Create Subscription",
                  url: "/scorecards/create-subscription",
                },
                { name: "Subscriptions", url: "/scorecards/subscriptions" },
                {
                  name: "Markets",
                  url: "/scorecards/markets",
                },
                { name: "Update Markets", url: "/scorecards/update-markets" },
              ]}
            />
            <HeaderDropdown
              key={"Period Release"}
              title={"Period Release"}
              menuItems={[
                { name: "Retail Intelligence", url: "/period-release/retail-intelligence" },
                { name: "Legacy Scorecards", url: "/period-release/legacy-scorecards" }
              ]}
            />
            <HeaderDropdown
              key={"NOSHE"}
              title={"NOSHE"}
              tag={true}
              menuItems={[
                {
                  name: "Access Admin",
                  url: "/noshe/access-admin",
                },
                {
                  name: "Available Scorecards",
                  url: "/noshe/available-scorecards",
                },
                  {
                      name: "IFrame Tester",
                      url: "/noshe/iframe-tester",
                  },
              ]}
            />
            <HeaderDropdown
              key={"Powertabs"}
              title={"Powertabs"}
              menuItems={[
                { name: "Subscription List", url: "/powertabs" },
                {
                  name: "Create Subscription",
                  url: "/powertabs/create-subscription",
                },
                { name: "Powertabs Instances", url: "/powertabs/instances" },
              ]}
            />
            <HeaderDropdown
              key={"C&S"}
              title={"C&S"}
              tag={true}
              menuItems={[
                  { name: "Subscriptions", url: "/cs/subscriptions" },
                  { name: "Admin Options", url: "/cs/admin" },
                  { name: "VM Instances", url: "/cs/vm-instances" },
              ]}
            />
            <HeaderTile
              menuItems={[{ name: "Mid-Market Report", url: "/mid-market-report" }]}
              key={"Mid-Market Report"}
            />
          </nav>
        </div>
      </div>
    </Fragment>
  );
};
