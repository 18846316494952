import "../../../../scss/AccessAdmin.scss";
import {CONTRACT_BY_ACCOUNT} from "../queries.js";
import {makeApolloClientCall} from "../../../../helpers/queryNirvanaCoreApi.js";

import React, {useEffect, useMemo, useState} from "react";
import Page from "../../../Page.jsx";
import {Backdrop, Box, Button, CircularProgress, Modal, Tooltip,} from "@mui/material";
import {AccountModal} from "../AccountModal.jsx";
import {ContractInfoModal} from "../ContractInfoModal.jsx";
import axios from "axios";
import jwtDecode from "jwt-decode";
import {IframeTester} from "../IframeTester.jsx";
import {NewCompanyModal} from "../NewCompanyModal.jsx";
import {useFetchAccounts} from "./AccessAdmin.hooks.jsx";
import {handleFalse, handleTrue} from "./AccessAdmin.handlers.js";
import {DataGrid, GridToolbarQuickFilter} from "@mui/x-data-grid";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const NOSHE_SUBSCRIPTION_ID = "e308aa73-51b7-11eb-a8c0-0aff710e7947";
export const LOG_ACTION_API = `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/audit-log/add-log`;

/* istanbul ignore next */
export const AccessAdmin = () => {
	const { accountsCompany, isLoading, fetchAccounts, setAccountsCompany } =
		useFetchAccounts();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [rowData, setRowData] = useState(undefined);
	const [contractData, setContractData] = useState({});
	const [iframeId, setIframeId] = useState(null);
	const [iframeModal, setIframeModal] = useState(false);
	const [availableScorecards, setAvailableScoreCards] = useState([]);
	const [destiniCompanies, setDestiniCompanies] = useState(new Map());

	const [modalState, setModalState] = useState({
		isContractModalOpen: false,
	});
	const [loaderState, setLoaderState] = useState({
		isFetchingScorecards: true,
		isFetchingContract: false,
		isFetchingDestiniCompanies: true,
	});
	const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);

	const auth0AccessToken = window.localStorage.getItem("access_token");
	const userEmail = auth0AccessToken
		? jwtDecode(auth0AccessToken)["https://aot/email"]
		: "";
	const axiosHeader = {
		Authorization: `Bearer ${auth0AccessToken}`,
	};

	const getApplyQuickFilterFn = (value) => {
		return (cellValue) => {
			const { row } = cellValue;
			const rowValue = row.brands
				.concat([
					row.name,
					row.companyId,
					row.satoriOrganizationId,
					row.destiniClientId,
				])
				.join(",");
			return rowValue.toUpperCase().includes(value.toUpperCase());
		};
	};

	const columns = [
		{
			field: "name",
			headerName: "Company Name",
			flex: 1,
            headerClassName: 'companies-table-header',
			getApplyQuickFilterFn,
			renderCell: (param) => {
				const style = {
					cursor: param.row.uuid && "pointer",
					color: !param.row.uuid && "gray",
					textDecoration: param.row.uuid && "underline",
				};

				return (
					<>
						<span
							style={style}
							onClick={() => {
								if (!param.row.uuid) {
									return;
								}

								setLoaderState({
									...loaderState,
									isFetchingContract: true,
								});
								const data = param.row;
								fetchContractByAccount(data.accountUuid)
									.then((result) => {
										const nosheSubscription =
											result.data?.accountSubscriptions?.find(
												(accountSubscription) => {
													return (
														accountSubscription
															?.subscription
															?.uuid ===
														NOSHE_SUBSCRIPTION_ID
													);
												}
											);
										const contract = {
											...result.data,
											nosheSubscription: {
												...nosheSubscription,
											},
											companyName: data.name,
											companyUuid: data.uuid,
										};
										setContractData(contract);
									})
									.catch((e) => {
										console.log(e);
										setContractData({
											companyName: data.name,
											companyUuid: data.uuid,
										});
									})
									.finally(() => {
										setLoaderState({
											...loaderState,
											isFetchingContract: false,
										});
										setModalState({
											...modalState,
											isContractModalOpen: true,
										});
									});
							}}
						>
							{param.row.name}
						</span>
						{param.row.brands.length > 0 && (
							<Tooltip
								title={"Brands: " + param.row.brands.join(", ")}
								placement="right"
								style={{
									marginLeft: "10px",
								}}
							>
								<InfoOutlinedIcon color={"info"}/>
							</Tooltip>
						)}
					</>
				);
			},
		},
		{
			field: "hasScorecard",
			headerName: "Available",
			flex: 1,
            headerClassName: 'companies-table-header',
			headerAlign: 'center',
			align: 'center',
			width: 100,
			maxWidth: 100,
			filterable: false,
			renderCell: (param) => {
				return <div>
					{ param.row.hasScorecard ? <CheckBoxIcon color="success"/> : <CheckBoxOutlineBlankIcon color="disabled"/> }
				</div>
			}
		},
		{
			field: "companyId",
			headerName: "Company Unique ID",
			flex: 1,
            headerClassName: 'companies-table-header',
			headerAlign: 'center',
			align: 'center',
			width: 300,
			maxWidth: 350,
			filterable: false,
		},
		{
			field: "satoriOrganizationId",
			headerName: "Satori Org ID",
			flex: 1,
            headerClassName: 'companies-table-header',
			headerAlign: 'center',
			align: 'center',
			width: 200,
			maxWidth: 200,
			filterable: false,
			renderCell: (param) => {
				return (
					<span
						data-testid={`satori-org-id-${param.row.satoriOrganizationId}`}
						style={{
							textDecoration: "underline",
							cursor: "pointer",
						}}
						onClick={() => {
							setIframeId(param.row.satoriOrganizationId);
							setIframeModal(true);
						}}
					>
						{param.row.satoriOrganizationId}
					</span>
				);
			},
		},
		{
			field: "destiniClientId",
			headerName: "Destini Client ID",
			flex: 1,
            headerClassName: 'companies-table-header',
			headerAlign: 'center',
			align: 'center',
			width: 200,
			maxWidth: 200,
			filterable: false,
			renderCell: (param) => {
				return (
					<span
						data-testid={`destini-id-${param.row.destiniClientId}`}
						style={{
							textDecoration: "underline",
							cursor: "pointer",
						}}
						onClick={() => {
							setIframeId(param.row.destiniClientId);
							setIframeModal(true);
						}}
					>
						{param.row.destiniClientId}
					</span>
				);
			},
		},
		{ field: "brands", headerName: "Brands", flex: 1, filterable: false },
		{
			field: "setup",
			selectable: false,
			headerName: "Action",
			headerAlign: 'center',
			align: 'center',
			width: 80,
			maxWidth: 80,
			flex: 1,
            headerClassName: 'companies-table-header',
			filterable: false,
			renderCell: (param) => {
				return (
					<Button
						size={"small"}
						variant={"outlined"}
						onClick={() => {
							const data = param.row;
							setRowData({
								...data,
								needAccountSetup: data.needAccountSetup,
								satoriOrganizationId:
									data.satoriOrganizationId ?? "",
								destiniClientId: data.destiniClientId ?? "",
							});
							setIsModalOpen(true);
						}}
					>
						Setup
					</Button>
				);
			},
		},
	];

	const handleClose = () => setIsModalOpen(false);

	const onDataUpdate = (updatedData) => {
		setAccountsCompany((prev) => {
			const updatedDataIndex = prev.findIndex(
				(company) => company.uuid === updatedData.companyUuid
			);
			const copiedDataTable = [...prev];
			copiedDataTable[updatedDataIndex] = {
				...accountsCompany[updatedDataIndex],
				satoriOrganizationId: updatedData.satoriOrganizationId ?? "",
				destiniClientId: updatedData.destiniClientId ?? "",
			};
			return copiedDataTable;
		});
	};

	const handleLogAction = async (updateInfo) => {
		axios
			.post(
				LOG_ACTION_API,
				{
					data: {
						email: userEmail,
						env: process.env.REACT_APP_ENV,
						...updateInfo,
					},
				},
				{
					headers: axiosHeader,
				}
			)
			.then((response) => {
				console.log(response);
			})
			.catch((error) => {
				console.log("error: ", error);
			});
	};

	const fetchContractByAccount = async (accountUuid) => {
		return await makeApolloClientCall(CONTRACT_BY_ACCOUNT, false, {
			accountUuid,
		});
	};

	useEffect(() => {
		const fetchScorecards = async () => {
			axios
				.get(
					`${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/available-scorecards/list`,
					{ headers: axiosHeader }
				)
				.then(({ data }) => {
					setAvailableScoreCards(data);
				})
				.finally(() =>
					setLoaderState((p) => {
						return { ...p, isFetchingScorecards: false };
					})
				);
		};
		fetchScorecards().then();
	}, []);

	useEffect(() => {
		axios
			.get(
				`${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/companies/list`,
				{ headers: axiosHeader }
			)
			.then(({ data }) => {
				setDestiniCompanies(
					new Map(
						data.map((dc) => [dc.company.toUpperCase(), dc.brands])
					)
				);
			})
			.finally(() =>
				setLoaderState((p) => {
					return { ...p, isFetchingDestiniCompanies: false };
				})
			);
	}, []);

	const companies = useMemo(() => {
		const availableCompaniesSet = new Set(
			accountsCompany.map((account) => account.name.toUpperCase())
		);

		let list = [];
		list = list.concat(
			accountsCompany.map((company) => {
				return {
					...company,
					brands:
						destiniCompanies.get(company.name.toUpperCase()) ?? [],
				};
			})
		);

		destiniCompanies.forEach((value, key) => {
			if (!availableCompaniesSet.has(key.toUpperCase())) {
				list.push({
					destiniClientId: "",
					name: key,
					satoriOrganizationId: "",
					uuid: "",
					companyId: "",
					accountUuid: "",
					brands: value,
					needAccountSetup: true,
				});
			}

			availableCompaniesSet.add(key);
		});

		const noCompanyWithScorecards = availableScorecards.filter(
			(scorecard) => !availableCompaniesSet.has(scorecard.toUpperCase())
		);

		return list
			.map((account) => {
				return {
					...account,
					hasScorecard: availableScorecards.includes(account.name),
				};
			})
			.concat(
				noCompanyWithScorecards.map((scorecard) => ({
					destiniClientId: "",
					satoriOrganizationId: "",
					uuid: "",
					companyId: "",
					accountUuid: "",
					name: scorecard,
					hasScorecard: true,
					needAccountSetup: true,
					brands: [],
				}))
			)
			.sort((a, b) => a.name.localeCompare(b.name));
	}, [accountsCompany, availableScorecards, destiniCompanies]);

	if (isLoading) {
		return (
			<CircularProgress
				className={"spinning-loader"}
				data-testid={"main-loader"}
			/>
		);
	}

	function QuickSearchToolbar() {
		return (
			<Box
				sx={{
					p: 1,
					pb: 0,
				}}
				style={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<Button
					variant="contained"
					size={"medium"}
					onClick={handleTrue(setIsCompanyModalOpen)}
					style={{ marginRight: "1em" }}
				>
					Add Company
				</Button>
				<GridToolbarQuickFilter
					debounceMs={500}
					size={"medium"}
					data-testid={"datagrid-quick-filter"}
					quickFilterParser={(searchInput) => {
						return [searchInput];
					}}
					variant="outlined"
					sx={{ paddingBottom: 0 }}
				/>
			</Box>
		);
	}

	return (
		<div>
			<Page name={"Accounts"}>
				<DataGrid
					sx={{
						"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
							outline: "none !important",
						},
						".MuiDataGrid-columnHeaderTitle": {
							fontWeight: "bold !important",
							overflow: "visible !important",
						},
						"& .MuiDataGrid-sortIcon": {
							opacity: "inherit !important",
						},
					}}
					disableVirtualization={
						process.env.REACT_APP_DISABLE_VIRTUALIZATION ?? false
					}
					columns={columns}
					rows={companies}
					getRowId={(row) => row.name + row.companyId + row.accountUuid}
					initialState={{
						filter: {
							filterModel: {
								items: [],
								quickFilterValues: [],
								quickFilterExcludeHiddenColumns: false,
							},
						},
						pagination: {
							paginationModel: {
								pageSize: 25,
							}
						},
						columns: {
							columnVisibilityModel: {
								brands: false,
							},
						},
					}}
					disableColumnFilter
					disableColumnSelector
					disableDensitySelector
					disableColumnMenu
					disableRowSelectionOnClick
					disableSelectionOnClick
					isRowSelectable={() => false}
					slots={{ toolbar: QuickSearchToolbar }}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
						},
					}}
				/>

				<NewCompanyModal
					isOpen={isCompanyModalOpen}
					handleClose={handleFalse(setIsCompanyModalOpen)}
					loadCompanies={fetchAccounts}
					handleLogAction={handleLogAction}
				/>
				<AccountModal
					isOpen={isModalOpen}
					handleClose={handleClose}
					data={rowData}
					onDataUpdate={onDataUpdate}
					handleLogAction={handleLogAction}
					loadCompanies={fetchAccounts}
				/>
				<ContractInfoModal
					isOpen={modalState.isContractModalOpen}
					handleClose={() =>
						setModalState({
							...modalState,
							isContractModalOpen: false,
						})
					}
					data={contractData}
				/>
				<Modal
					open={iframeModal}
					data-testid={"iframe-modal"}
					onClose={() => setIframeModal(false)}
				>
					<IframeTester
						height={"600"}
						width={"100%"}
						clientId={iframeId}
						showControls={false}
					/>
				</Modal>
				<Backdrop
					sx={{
						color: "#FFFFFF",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open={loaderState.isFetchingContract}
				>
					<CircularProgress className={"spinning-loader"} />
				</Backdrop>
			</Page>
		</div>
	);
};
