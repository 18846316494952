import "../../../scss/MidMarket.scss";
import Page from "../../Page.jsx";
import { EmailTextBox } from "../../emailTextBox/EmailTextBox.jsx";
import React, { useCallback, useEffect, useState } from "react";
import { CustomNoRowsOverlay } from "../common/components/CustomNoRowsOverlay.js";
import {DataGrid, GridToolbarQuickFilter} from "@mui/x-data-grid";
import { Box, Button, CircularProgress } from "@mui/material";
import { RetailerDataModal } from "./RetailerDataModal";
import axios from "../../../api/axiosApiClient";
import ConfirmationDialog from "../common/dialog/ConfirmationDialog.jsx";
import { toast, ToastContainer } from "react-toastify";
import { makeApolloClientCall } from "../../../api/makeApolloApiCall.js";
import { MARKETS_QUERY } from "../cns/queries.js";
import { REQUEST_ENDPOINT } from "./MidMarket.constant.js";
import { useGlobalEmailRecipients } from "./MidMarket.hooks.jsx";
import { handleMidmarketTrigger } from "./MidMarket.handlers.js";

const SHINING_LANDING_REQUEST_ENDPOINT = `${process.env.REACT_APP_RIS_API_URL}/shining-landing/data-group/list`;

export const MidMarket = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [retailers, setRetailers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [dataGroupList, setDataGroupList] = useState([]);
  const [marketKeysList, setMarketKeysList] = useState([]);
  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [disableTrigger, setDisableTrigger] = useState(false);
  const { recipients, updateRecipientHandler } = useGlobalEmailRecipients();
  const COLUMNS = [
    { headerName: "Name", field: "name", flex: 1 },
    { headerName: "Market Keys", field: "marketKeys", flex: 1 },
    { headerName: "Comp Market Keys", field: "compMarketKeys", flex: 1 },
    { headerName: "Data Group Id", field: "dataGroupId", flex: 1 },
  ];
  useEffect(() => {
    const configContentCall = axios.get(`${REQUEST_ENDPOINT}/retailer/config`);
    const marketsQueryCall = makeApolloClientCall(MARKETS_QUERY);
    const dataGroupCall = axios.get(SHINING_LANDING_REQUEST_ENDPOINT);

    Promise.all([configContentCall, marketsQueryCall, dataGroupCall])
      .then((response) => {
        setRetailers(response[0].data);

        const mappedMarkets = response[1].markets.map((market) => ({
          description: `${market.market_key} | ${market.description}`,
          displayName: market.market_key,
          value: +market.market_key,
        }));
        setMarketKeysList(mappedMarkets);

        const mappedDataGroups = response[2].data.map((dataGroup) => ({
          description: `${dataGroup.id} | ${dataGroup.group_name}`,
          displayName: dataGroup.group_name,
          value: +dataGroup.id,
        }));
        setDataGroupList(mappedDataGroups);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const handleDelete = useCallback(
    async (confirmState) => {
      if (!confirmState) {
        setConfirmModalOpen(confirmState);
        return;
      }
      try {
        const { data: newRetailers } = await axios.delete(
          `${REQUEST_ENDPOINT}/retailer/delete-config`,
          {
            params: {
              config_id: selectedRowData?.id,
            },
          }
        );
        setRetailers(newRetailers);
        setConfirmModalOpen(false);
        toast.success("Deleted record successfully.");
      } catch (e) {
        toast.error("Error while deleting record.");
      }
    },
    [selectedRowData]
  );

  if (isLoading) {
    return (
      <CircularProgress
        className={"spinning-loader"}
        data-testid={"main-loader-mid-market"}
      />
    );
  }

  const transformRetailerSelection = (retailer) => {
    return {
      marketKeys: retailer[0].marketKeys.map((marketKey) => ({
        value: marketKey,
        displayName: marketKey.toString(),
      })),
      compMarketKeys: retailer[0].compMarketKeys.map((compMarketKey) => ({
        value: compMarketKey,
        displayName: compMarketKey.toString(),
      })),
      dataGroup: retailer[0].dataGroupId,
      name: retailer[0].name,
      id: retailer[0].id,
    };
  };

    function QuickSearchToolbar() {
        return (
            <Box sx={{p: 1, pb: 0,}}
                 style={{display: "flex", justifyContent: "end", paddingBottom: "10px", borderBottom: "1px solid gray"}}
            >
                <GridToolbarQuickFilter
                    debounceMs={500}
                    size={"medium"}
                    data-testid={"datagrid-quick-filter"}
                    quickFilterParser={(searchInput) => {
                        return [searchInput];
                    }}
                    variant="outlined"
                    sx={{ paddingBottom: 0 }}
                />
            </Box>
        );
    }

  return (
    <Page name={"Mid-Market Report"}>
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: "95%",
          marginLeft: "auto",
          marginRight: "auto",
          gap: "20px",
        }}
      >
        <div>
          <EmailTextBox
            label={"Global Emails"}
            value={recipients}
            setError={() => console.log("Error")}
            onChange={updateRecipientHandler}
            testId={"email-input"}
          />
        </div>
        <DataGrid
          slots={{ noRowsOverlay: CustomNoRowsOverlay, toolbar: QuickSearchToolbar }}
          columns={COLUMNS}
          rows={retailers}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          getRowId={(row) => row.name}
          loading={isLoading}
          checkboxSelection
          rowHeight={40}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setSelectedRetailers(newRowSelectionModel);
          }}
          isRowSelectable={() => true}
          disableVirtualization={!!process.env.REACT_APP_DISABLE_VIRTUALIZATION}
          slotProps={{
              toolbar: {
                  showQuickFilter: true,
              },
          }}
          disableColumnFilter
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            marginTop: "1em",
            justifyContent: "space-between",
          }}
        >
          <Button
            disabled={selectedRetailers.length < 1 || disableTrigger}
            style={{ display: "flex" }}
            variant="contained"
            onClick={async (e) => {
              e.preventDefault();
              setDisableTrigger(true);
              const filteredRetailers = retailers.filter((f) =>
                selectedRetailers.includes(f.name)
              );

              const confBody = {
                mid_market_spec: filteredRetailers,
                email_recipients: recipients,
              };

              await handleMidmarketTrigger(confBody).finally(() =>
                setDisableTrigger(false)
              );
            }}
            color="success"
          >
            Trigger DAG
          </Button>

          <div>
            <Button
              disabled={selectedRetailers.length !== 1}
              variant="outlined"
              size="large"
              color="error"
              style={{ marginRight: "8px" }}
              data-testid={`delete-button`}
              onClick={() => {
                const filteredRetailers = retailers.filter((f) =>
                  selectedRetailers.includes(f.name)
                );
                const retailersToDelete =
                  transformRetailerSelection(filteredRetailers);

                setSelectedRowData(retailersToDelete);
                setConfirmModalOpen(true);
              }}
            >
              Remove
            </Button>

            <Button
              size="large"
              disabled={selectedRetailers.length !== 1}
              variant="outlined"
              data-testid={`edit-button`}
              onClick={() => {
                const filteredRetailers = retailers.filter((f) =>
                  selectedRetailers.includes(f.name)
                );

                const retailerToEdit =
                  transformRetailerSelection(filteredRetailers);

                setSelectedRowData(retailerToEdit);
                setIsModalOpen(true);
              }}
              sx={{ marginRight: 1 }}
            >
              Edit
            </Button>

            <Button
              variant="contained"
              disabled={selectedRetailers.length > 0}
              size="large"
              fullWidth={false}
              data-testid={`add-button`}
              onClick={() => setIsModalOpen(true)}
            >
              Add
            </Button>
          </div>
        </Box>

        <RetailerDataModal
          isOpen={isModalOpen}
          handleClose={() => {
            setIsModalOpen(false);
            setSelectedRowData(null);
          }}
          data={selectedRowData}
          marketKeyList={marketKeysList}
          dataGroupList={dataGroupList}
          setRetailers={setRetailers}
        />
        <ConfirmationDialog
          isOpen={confirmModalOpen}
          message={`Are you sure you want to delete the selected row? (${
            selectedRowData?.name || selectedRowData?.id
          })`}
          handleConfirm={handleDelete}
          handleClose={() => setConfirmModalOpen(false)}
          title={"Confirm deletion"}
          data-testid={"confirm-dialog"}
        />
      </Box>
    </Page>
  );
};
