import {Button} from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';
import {makeApolloClientCall} from "../../../../../api/makeApolloApiCall.js";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import ConfirmationDialog from "../../dialog/ConfirmationDialog.jsx";

export const DeleteSubscriptionButton = ({deleteMutation, id, filePrefixName}) => {
    const [confirmed, setConfirmed] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)

    const handleDialogClose = (value) => {
        setOpenDialog(value)
    }

    const handleConfirm = (value) => {
        setOpenDialog(false)
        setConfirmed(value)
    }

    useEffect((() => {
        if (confirmed){
            setIsDisabled(true)
            makeApolloClientCall(deleteMutation, false, {id: id})
                .then(() => {
                    toast.success("Delete Success. Reloading page...",
                        {
                            onClose: function () {
                                window.location.reload();
                            }
                        });
                })
                .catch(error => toast.error(`${error}`))
                .finally(() => {
                        setIsDisabled(false)
                    }
                )
        }
    }),[confirmed])
    return <div>
        <Button
            variant="outlined"
            disabled={isDisabled}
            color="error"
            onClick={() => {
                setOpenDialog(true)
            }
            }>Delete
        </Button>
        <ConfirmationDialog
            isOpen={openDialog}
            message={"Are you sure you want to delete " + filePrefixName + "?"}
            handleClose={handleDialogClose}
            handleConfirm={handleConfirm}
            title={"Confirm Delete"}
        />
    </div>
}
