import * as React from "react";
import { Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { AuditLogTable } from "../../auditLogTable/AuditLogTable.jsx";

export const LOG_ACTION_API = `${process.env.REACT_APP_RIS_API_URL}/legacy-scorecards/audit-log/logs`;

export const ContractInfoModal = ({isOpen, handleClose, data}) => {
    return (
        <div className={"contract-info-modal"}>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={"md"}
                data-testid={"contract-info-dialog"}
            >
                <DialogTitle id="alert-dialog-title" className={"account-dialog-title"}>
                    <div>
                        {data?.companyName}
                        <p className={"uuid"}> {data?.companyUuid} </p>
                    </div>
                    <IconButton onClick={handleClose} className={"button-close"} test-id={"contract-modal-close-btn"}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className={"contract-info"}>
                        <TableContainer>
                            <Table sx={{ width: "100%"}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}><span className={"bold"}> CONTRACT </span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!data?.uuid ?
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2} style={{ textAlign: "center"}}>
                                                No Contract Found
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <Fragment>
                                            <TableRow key={"UUID"}>
                                                <TableCell component="th" scope="row">UUID</TableCell>
                                                <TableCell component="th" scope="row">{data?.uuid}</TableCell>
                                            </TableRow>
                                            <TableRow key={"Contract Number"}>
                                                <TableCell component="th" scope="row">Contract Number</TableCell>
                                                <TableCell component="th" scope="row">{data?.contractNumber}</TableCell>
                                            </TableRow>
                                            <TableRow key={"Start Date"}>
                                                <TableCell component="th" scope="row">Start Date</TableCell>
                                                <TableCell component="th" scope="row">{data?.startDate}</TableCell>
                                            </TableRow>
                                            <TableRow key={"End Date"}>
                                                <TableCell component="th" scope="row">End Date</TableCell>
                                                <TableCell component="th" scope="row">{data?.endDate}</TableCell>
                                            </TableRow>
                                        </Fragment>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer >
                            <Table sx={{ width: "100%", marginTop: "15px" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell colSpan={2}><span className={"bold"}> NOSHE SUBSCRIPTION </span></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!data?.nosheSubscription?.subscription ?
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={2} style={{ textAlign: "center"}}>
                                                No NOSHE Subscription Found
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <Fragment>
                                            <TableRow key={"ID"}>
                                                <TableCell component="th" scope="row">ID</TableCell>
                                                <TableCell component="th" scope="row">{data?.nosheSubscription?.id}</TableCell>
                                            </TableRow>
                                            <TableRow key={"Type"}>
                                                <TableCell component="th" scope="row">Type</TableCell>
                                                <TableCell component="th" scope="row">
                                                    {data?.nosheSubscription?.subscription?.name} ({data?.nosheSubscription?.subscription?.uuid})
                                                </TableCell>
                                            </TableRow>
                                        </Fragment>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <AuditLogTable endpoint={LOG_ACTION_API} params={{company: data?.companyUuid }} />
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}