import {
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import * as React from "react";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";

export const AuditLogTable = ({ endpoint, params }) => {
	const [logs, setLogs] = useState([]);
	const [logRequestLoading, setLogRequestLoading] = useState(false);

	const logRequest = async (params) => {
		setLogRequestLoading(true);
		const auth0AccessToken = window.localStorage.getItem("access_token");

		await axios
			.get(endpoint, {
				headers: {
					Authorization: `Bearer ${auth0AccessToken}`,
				},
				params,
			})
			.then((response) => {
				response?.data?.sort((a, b) =>
					b.timestamp?.localeCompare(a.timestamp)
				);
				setLogs(response.data);
			})
			.catch((error) => {
				console.log("error: ", error);
			})
			.finally(() => {
				setLogRequestLoading(false);
			});
	};

	useEffect(() => {
		logRequest(params);
	}, [params]);

	return (
		<TableContainer>
			<Table sx={{ width: "100%", marginTop: "15px" }}>
				<TableHead>
					<TableRow>
						<TableCell colSpan={2}>
							<span className={"bold"}> AUDIT LOGS </span>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{logRequestLoading || !logs || logs.length === 0 ? (
						<TableRow>
							<TableCell
								component="th"
								scope="row"
								colSpan={2}
								style={{ textAlign: "center" }}
							>
								{logRequestLoading ? (
									<CircularProgress />
								) : (
									"No Logs"
								)}
							</TableCell>
						</TableRow>
					) : (
						<Fragment>
							{logs.length >= 0 &&
								logs.map(
									(
										{ email, timestamp, ...otherValues },
										index
									) => (
										<TableRow key={"log" + index}>
											<TableCell
												component="th"
												scope="row"
											>
												{email}
											</TableCell>
											<TableCell
												component="th"
												scope="row"
											>
												{new Date(
													timestamp
												).toLocaleString("en-US", {
													timeZone:
														"America/Chicago",
												})}
											</TableCell>
											<TableCell
												component="th"
												scope="row"
											>
												<div>
													<pre>
														{JSON.stringify(
															otherValues,
															null,
															2
														)}
													</pre>
												</div>
											</TableCell>
										</TableRow>
									)
								)}
						</Fragment>
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
