import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmationDialog({isOpen, message, handleClose, handleConfirm, title, showCancelButton = true}) {
    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    'data-testid': 'confirm-dialog'
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {showCancelButton && <Button type="button" data-testid="confirm-close" onClick={()=>handleConfirm(false)}>Cancel</Button>}
                    <Button type="button" data-testid="confirm-execute" onClick={()=> handleConfirm(true)} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
